@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
@import './quasar.variables.sass'
@import './quasar.overrides.sass'
@import '../../../../lib/css/hc.sass'
@import '../../../../lib/css/loops.sass'
@import '../../../../lib/css/unicons.sass'
@import '../../../../lib/css/poppins.sass'

// Defaults

body
  font-size: 15px
  font-family: 'Poppins', sans-serif
  color: $dark

// Dark mode declaration
body.body--light
  --q-primary: #0086FF
  --q-secondary: #FD0F64
  --q-accent: #FFDD66
  --q-dark: #262338
  --q-positive: #00CC67
  --q-negative: #FD0F64
  --q-info: #66D6FF
  --q-warning: #FFDD66

  .hc-link
    text-decoration: none
    color: $grey-7
    &:hover
      color: $primary

body.body--dark
  --q-primary: #0086FF
  --q-secondary: #FD0F64
  --q-accent: #FFDD66
  // --q-dark: #F7F7FC
  --q-positive: #00FE80
  --q-negative: #FD0F64
  --q-info: #E5F8FF
  --q-warning: #F3D9A5

  .hc-link
    text-decoration: none
    color: $grey-1
    &:hover
      color: $primary-dark

// Custom classes
// The ones containing color values should be double declared
// in the body--light and body--dark selectors above
